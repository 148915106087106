import React, { useLayoutEffect } from 'react'
import Carousels from '../../components/carousel'
import Layout from '../../components/layout'
import Pager from '../../components/pager'
import Seo from '../../components/seo'
// import pictureFeedNews from '../../images/notices.jpeg'
import { graphql, Link, navigate } from 'gatsby'
import logo from '../../images/newspaper.png'
import urlSlug from 'url-slug'
import { useWindowSize } from '../../hooks/useWindowSize'
import { NEWS_GENERAL_ROUTE } from '../../utils/variables/generalRoutes'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'

const newListOu = ({ data, pageContext }) => {
  const {
    allStrapiNovelties: news,
    strapiOrganizationalUnit: organizationalUnit
  } = data

  const [width] = useWindowSize()
  const objCarousel = {
    visible: true,
    slide: organizationalUnit.mainCover?.noveltiesCover
  }

  useLayoutEffect(() => {
    if (width < 768) {
      return window.scrollTo({ top: 265, behavior: 'smooth' })
    } else if (width < 1400) {
      return window.scrollTo({ top: 270, behavior: 'smooth' })
    } else if (width < 1600) {
      return window.scrollTo({ top: 304, behavior: 'smooth' })
    } else {
      return window.scrollTo({ top: 370, behavior: 'smooth' })
    }
  }, [width])

  const handleClickBack = (e) => {
    e.preventDefault()
    navigate(`/${urlSlug(organizationalUnit.slug)}/${NEWS_GENERAL_ROUTE}/`, {
      replace: false
    })
  }

  return data ? (
    <Layout page={organizationalUnit} logo={organizationalUnit.logo}>
      <Seo title={`Noticias ${organizationalUnit.name}`} />
      <section className="schedule-feed mb-3">
        {objCarousel?.slide && objCarousel?.slide.length > 0 && (
          <div className="d-flex flex-column schedule-header-carousel">
            <Carousels carousel={objCarousel} keyName={'newsFeed-'} />
          </div>
        )}
        <div className="container">
          <div className="my-5">
            <div className="d-flex align-items-center mb-3 row">
              <div className="col-12 col-md-6">
                <div className=" border-bottom">
                  <h5 className="text-uppercase title-secondary">
                    <FontAwesomeIcon icon={faNewspaper} className="mr-2" />
                    Noticias{' '}
                    <span className="font-weight-bold pb-1 text-category">
                      {pageContext.category}
                    </span>
                  </h5>
                  <h5 className="text-uppercase font-weight-bold text-main">
                    {organizationalUnit.name}
                  </h5>
                </div>
              </div>

              <div className=" col-12 col-md-6 d-flex mb-auto mt-separate">
                <button
                  className="btn btn-sm ucc-background-color border-ucc-boton ml-auto"
                  onClick={handleClickBack}
                >
                  Volver a categorías
                </button>
              </div>
            </div>
            <div className="row">
              {news.nodes.map((item) => {
                if (
                  item.slug &&
                  (item.dateTo === null ||
                    moment(item.dateTo, 'YYYY/MM/DD').isSameOrAfter(moment()))
                )
                  return (
                    <div
                      className={`mb-4 col-12 col-md-4 'hightlighted'
                   `}
                      key={`noveltiesNodesId-${item.strapiId}`}
                    >
                      <div className="h-100 d-flex flex-column rounded shadow-sm news-card overflow-hidden position-relative">
                        <div
                          className="news-image"
                          style={{
                            backgroundImage: `url(

                            ${item.thumbnail ? item.thumbnail.url : logo} 
                       
                        )`
                          }}
                        ></div>
                        <div className="flex-grow-1 p-4 d-flex flex-column news-info">
                          <h6>
                            <small>
                              {moment(item.date)
                                .locale('es')
                                .format('DD-MM-YYYY')}
                            </small>
                          </h6>
                       
                          {/* Etiqueta de la noticia - novelties categories */}
                          {/* <div className="bg-sec px-2 py-1 text-white mb-2 fit-content">                            
                            {item.novelties_categories?.map((noveltieCategory, i) => {
                              if ((item.novelties_categories.length - 1) > i) {
                                return noveltieCategory?.name + " - "
                              } else {
                                return noveltieCategory?.name
                              }
                            })}
                          </div> */}

                          <h5 className="font-weight-bold">{item.title}</h5>
                          <div className='noticies-markdown markdown-format'>
                            <p>{item.summary}</p>
                          </div>

                          <Link
                            to={`/${NEWS_GENERAL_ROUTE}/${item.slug}`}
                            className={`btn btn-outline-info mt-auto fit-content text-uppercase 
                              rounded-pill px-4 font-secondary eventuality-button-font-size
                            }`}
                          >
                            VER MÁS
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
              })}
            </div>
            <Pager pageContext={pageContext} />
          </div>
        </div>
      </section>
    </Layout>
  ) : null
}

export default newListOu
export const newListQueryOU = graphql`
  query(
    $skip: Int
    $limit: Int
    $date: Date
    $orgUnitId: Int
    $category: String
  ) {
    allStrapiNovelties(
      skip: $skip
      limit: $limit
      sort: { fields: date, order: DESC }
      filter: {
        date: { lte: $date }
        organizational_units: { elemMatch: { id: { eq: $orgUnitId } } }
        novelties_categories: { elemMatch: { name: { eq: $category } } }
      }
    ) {
      nodes {
        strapiId
        title
        slug
        summary
        date
        dateTo
        thumbnail {
          url
        }
        novelties_categories {
          name
        }
      }
    }
    strapiOrganizationalUnit(strapiId: { eq: $orgUnitId }) {
      name
      slug
      logo {
        url
        image {
          url
        }
      }
      navbar {
        name
        icon {
          code
          type
        }
        careersList {
          name
          ucc_careers {
            name
            slug
          }
        }
        organizationalUnit {
          name
          icon {
            code
            type
          }
          organizational_unit {
            name
            slug
          }
        }
        organizational_unit {
          name
          slug
        }
        page {
          name
          slug
        }
        pages {
          name
          icon {
            code
            type
          }
          page {
            name
            slug
          }
        }
      }
      mainCover {
        noveltiesCover {
          url
          visible
          name
          imageDescription
          content
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
      }
    }
  }
`
